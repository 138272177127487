@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
//@import "node_modules/prismjs/themes/prism-dark.css";

$green: #28b76b;
$grey: #1C1C1C;
$white: #FFFFFF;
$black: #000000;
$red: #DA353C;

@import "prism";

html {
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }

  .header-banner {
    font-size: 44px;
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 22px;
  }

  .header-banner {
    font-size: 44px;
  }
}

.header-icons-container {
  height: 50px;

  .header-icons {
    svg {
      float: right;
      height: 40px;
      //fill: white;
      margin: 5px;
    }
  }
}

.header-banner {
  //height: 120px;
  padding: 15px 0;
  text-align: center;

  .header-banner-text {
    margin: auto;
    width: 100%;
    padding: 10px;
  }

  .header-banner-bg {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    overflow: hidden;

    &:before {
      content: "\f058";
      font-size: 200px;
      color: transparentize($white, 0.6);
      position: absolute;
      right: 0;
      top: 0;
      transform: skew(25deg, -25deg);
      moz-transform-origin: top left;
      transform-origin: top left;
    }
  }
}

body {
  //padding-top: $header-height;
}

.general-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

//h1, h2, h3, h4, h5, h6 {
//  color: $green;
//}

.fit-content {
  width: fit-content;
}

a {
  text-decoration: none;
}

.card {
  border: none;
  box-shadow: 1px 2px 2px 2px transparentize($grey, 0.6);
  color: $grey;
  height: 100%;

  background: none;

  .card-header {
    border: none;
    font-weight: bold;
    background: none;
  }

  &:hover {
    box-shadow: 0 0 5px 1px $green !important;
  }
}

#docs-nav {
  box-shadow: 0 0 10px 1px;
  width: 200px;
  max-width: 100%;
  height: 100vh;
  top: 0;
  //padding-top: $header-height;
  //margin-top: -$header-height;
}

.docs-content {
  height: 100%;
}

a {
  &:link, &:visited, &:hover, &:active {
    text-decoration: none;
  }
}

.display-none {
  display: none;
}

@each $size, $value in $grid-breakpoints {
  .display-#{$size}-none {
    @media screen and (min-width: $value) {
      display: none;
    }
  }

  .display-#{$size}-inherit {
    @media screen and (min-width: $value) {
      display: inherit;
    }
  }
}

@for $i from 1 through 6 {
  .h#{$i}, h#{$i} {
    color: darken($green, ($i - 1) * 5%);
  }
}
